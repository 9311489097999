// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

/**
 * Converts the entity forms of special HTML characters to their normal form.
 *
 * @param {String} value
 * @returns {String}
 */
export default value => String(value)
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, '\'')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
