// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu, MenuItem } from '@plesk/ui-library';
import { Locale } from 'jsw';

const Translate = Locale.getTranslate('components.forms.login');

const LocaleSwitcher = ({ locales, value: defaultValue, onChange }) => {
    const [value, setValue] = useState(defaultValue);

    const sortedLocales = useMemo(() => locales.slice().sort((a, b) => {
        if (b.code === 'en-US' || a.name > b.name) {
            return 1;
        }
        if (a.code === 'en-US' || b.name > a.name) {
            return -1;
        }
        return 0;
    }), [locales]);

    const handleChange = value => {
        setValue(value);
        onChange(value);
    };

    return (
        <Dropdown
            menuPlacement="bottom-end"
            menu={(
                <Menu
                    className="login-page__locale-switcher-menu"
                >
                    <MenuItem
                        active={value === 'default'}
                        onClick={() => handleChange('default')}
                    >
                        <Translate content="defaultLocale" />
                    </MenuItem>
                    {sortedLocales.map(({ code, name, isRtl }) => (
                        <MenuItem
                            key={code}
                            active={value === code}
                            onClick={() => handleChange(code)}
                            dir={isRtl ? 'rtl' : 'ltr'}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        >
            <Button
                className="login-page__locale-switcher-button pul-button--on-dark"
                tooltip={<Translate content="localeLabel" />}
                icon="globe"
                ghost
                caret
            />
        </Dropdown>
    );
};

LocaleSwitcher.propTypes = {
    locales: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        isRtl: PropTypes.bool.isRequired,
    })).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default LocaleSwitcher;
