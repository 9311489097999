// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Outlet } from 'react-router-dom';
import { usePageData } from 'helpers/pageData';
import PageLayout from 'components/layouts/PageLayout';

const PhpPagesLayout = () => {
    const data = usePageData();

    return (
        <PageLayout {...data}>
            <Outlet />
        </PageLayout>
    );
};

export default PhpPagesLayout;
