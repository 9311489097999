// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

const observers = new Map();
const getObservers = ns => {
    if (!observers.has(ns)) {
        observers.set(ns, []);
    }
    return observers.get(ns);
};

class Observer {
    /**
     * @param {Function} callback
     * @param {String} ns
     */
    static append(callback, ns) {
        getObservers(ns).push(callback);
    }

    /**
     * @param {String} ns
     * @param {Function} callback
     */
    static addEventListener(ns, callback) {
        this.append(callback, ns);
    }

    /**
     * @param {Function} callback
     * @param {String} ns
     */
    static appendAndCall(callback, ns) {
        getObservers(ns).push(callback);
        callback();
    }

    /**
     * @param {Object} event
     * @param {String} ns
     */
    static notify(event, ns) {
        getObservers(ns).forEach(callback => callback(event));
    }

    /**
     * @param {Function} callback
     * @param {String} ns
     */
    static remove(callback, ns) {
        const pos = getObservers(ns).indexOf(callback);
        if (pos !== -1) {
            getObservers(ns).splice(pos, 1);
        }
    }

    /**
     * @param {String} ns
     * @param {Function} callback
     */
    static removeEventListener(ns, callback) {
        this.remove(callback, ns);
    }
}

export default Observer;
