// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { makeVar, useReactiveVar } from '@apollo/client';

const toasts = makeVar([]);
let key = 0;

export const useToasts = () => useReactiveVar(toasts);

export const addToast = ({ intent, ...props }) => {
    toasts([
        ...toasts(),
        {
            key: key++,
            intent,
            autoClosable: ['success', 'info'].includes(intent) ? true : null,
            ...props,
        },
    ]);
};

export const removeToast = ({ key }) => toasts(toasts().filter(toast => toast.key !== key));

export default toasts;
