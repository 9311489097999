// Copyright 1999-2023. Plesk International GmbH. All rights reserved.
/* eslint-disable react/jsx-max-depth */

import classNames from 'classnames';
import { api, prepareUrl, createComponent, Locale } from 'jsw';
import JswComponent from './JswComponent';
import pageData, { usePageData } from '../../helpers/pageData';

const StatusMessages = () => {
    const { status = [] } = usePageData();

    const handleCloseMessage = message => {
        if (message.hideUrl) {
            api.post(prepareUrl(message.hideUrl));
        }
        pageData({
            ...pageData(),
            status: status.filter((_, key) => message.key !== key),
        });
    };

    const renderMessage = message => {
        const {
            componentType,
            status,
            content,
            title = Locale.getSection('components.status').lmsg(status),
            class: className,
            ...other
        } = message;

        if (componentType) {
            return (
                <JswComponent>
                    {() => createComponent({
                        componentType,
                        title,
                        type: status,
                        message: content,
                        attrs: {
                            'data-rendered-by-react': 'true',
                        },
                        ...other,
                    })}
                </JswComponent>
            );
        }

        const {
            key,
            id,
            source,
            hideUrl,
        } = message;

        return (
            <div
                key={key}
                id={id}
                className={classNames('msg-box', `msg-${status}`, className)}
                data-source={source}
                data-rendered-by-react
            >
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div className="msg-content">
                                        {hideUrl ? <span className="close" onClick={() => handleCloseMessage(message)} /> : null}
                                        {title ? (
                                            <span className="title">
                                                {title}{': '}
                                            </span>
                                        ) : null}
                                        <span
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{ __html: content }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return status.map((message, key) => renderMessage({ key, ...message }));
};

export default StatusMessages;
