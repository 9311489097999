// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useRef } from 'react';
import PropTypes from 'prop-types';
import MainHeaderSearch from './MainHeaderSearch';
import MainHeaderNavbar from './MainHeaderNavbar';

const PageHeader = ({ licenseStatus, searchUrl, ...props }) => {
    const headerRef = useRef();

    return (
        <div className="page-header" ref={headerRef}>
            {searchUrl ? (
                <MainHeaderSearch url={searchUrl} headerRef={headerRef} />
            ) : null}
            <MainHeaderNavbar {...props} />
        </div>
    );
};

PageHeader.propTypes = {
    licenseStatus: PropTypes.object,
    searchUrl: PropTypes.string,
};

PageHeader.defaultProps = {
    licenseStatus: null,
    searchUrl: null,
};

export default PageHeader;
