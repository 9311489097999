// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useRef, useState, useCallback, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, Menu, MenuItem, MenuDivider, MenuHeader, Icon, Status, Hint, Button } from '@plesk/ui-library';
import { keyCode, Locale } from 'jsw';
import { toFormData } from 'helpers/form';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import Link from 'plesk/components/Link';

import MAIN_HEADER_SEARCH_QUERY from 'queries/MainHeaderSearch.graphql';
import RECENT_SEARCH_HISTORY_QUERY from './RecentSearchHistoryQuery.graphql';
import DELETE_RECENT_SEARCH_RESULT_MUTATION from './DeleteRecentSearchResultMutation.graphql';

const Translate = Locale.getTranslate('components.search-bar');

const MENU_ITEM_CLASSNAME = 'main-header-search-result__item';

export const SEARCH_HISTORY_STORE_URL = '/cp/search/store';

const removeTouchEventListeners = ({ onDocumentTouchStart, onDocumentTouchMove, onDocumentTouchEnd }) => {
    document.removeEventListener('touchstart', onDocumentTouchStart);
    document.removeEventListener('touchmove', onDocumentTouchMove);
    document.removeEventListener('touchend', onDocumentTouchEnd);
};

const MainHeaderSearch = ({ url, headerRef }) => {
    const searchActionDelay = 300;
    const containerRef = useRef();
    const searchTermRef = useRef();
    const lastTermRef = useRef('');
    const menuRef = useRef();
    const timer = useRef(null);
    const resetTimeout = useRef(null);
    const enterPressed = useRef(false);
    const pendingRequest = useRef(null);
    const skipKeyUp = useRef(false);
    const touchMoved = useRef(false);
    const { data: { config, viewer } = {} } = useQuery(MAIN_HEADER_SEARCH_QUERY);
    const [history, setHistory] = useState();
    const [deleteRecentSearchResult] = useMutation(DELETE_RECENT_SEARCH_RESULT_MUTATION, {
        onCompleted: ({ deleteRecentSearchResult }) => setHistory(deleteRecentSearchResult.query.recentSearch),
    });

    const [isSearchFocused, setSearchFocused] = useState(false);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [results, setResults] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const apolloClient = useApolloClient();
    const isAdmin = viewer?.type === 'ADMIN';

    const fatalError = message => {
        // eslint-disable-next-line no-alert
        alert(message);
    };

    const handlePaste = () => scheduleSearch();

    const handleKeyUp = event => {
        if (skipKeyUp.current) {
            return;
        }
        if (event.keyCode === keyCode.ESC) {
            searchTermRef.current.blur();
            return;
        }
        if ([keyCode.UP_ARROW, keyCode.DOWN_ARROW, keyCode.LEFT_ARROW, keyCode.RIGHT_ARROW, keyCode.ENTER].indexOf(event.keyCode) !== -1) {
            return;
        }

        scheduleSearch();
    };

    const handleKeyDown = event => {
        skipKeyUp.current = event.ctrlKey || event.metaKey;

        if ([keyCode.UP_ARROW, keyCode.DOWN_ARROW].indexOf(event.keyCode) !== -1) {
            onArrowKeyPressed(event.keyCode);
            event.preventDefault();
        }

        enterPressed.current = (keyCode.ENTER === event.keyCode);
        if (enterPressed.current) {
            if (
                (pendingRequest.current && pendingRequest.current._complete)
                || (history && selectedItem !== null)
            ) {
                goToItem();
            } else {
                scheduleSearch();
            }
        }
    };

    const goToItem = () => {
        if (!menuRef.current) {
            return;
        }
        const elements = menuRef.current.querySelectorAll(`.${MENU_ITEM_CLASSNAME}`);
        const element = elements[selectedItem || 0];
        if (element) {
            element.click();
            searchTermRef.current.blur();
        }
    };

    const handleFocus = () => {
        if (resetTimeout.current) {
            clearTimeout(resetTimeout.current);
        }

        resetSearch();
        setSearchFocused(true);

        apolloClient.query({
            query: RECENT_SEARCH_HISTORY_QUERY,
        }).then(({ data }) => {
            setHistory(data.recentSearch);
        });

        document.addEventListener('touchstart', onDocumentTouchStart);
        document.addEventListener('touchmove', onDocumentTouchMove);
        document.addEventListener('touchend', onDocumentTouchEnd);
    };


    const handleBlur = () => {
        if (resetTimeout.current) {
            clearTimeout(resetTimeout.current);
        }

        resetTimeout.current = setTimeout(() => {
            resetSearch();
            setSearchFocused(false);
        }, 300);
    };

    const scheduleSearch = () => {
        setSearchInProgress(true);

        abortPreviousSearch();
        timer.current = setTimeout(findTerm, searchActionDelay);
    };

    const abortPreviousSearch = () => {
        if (pendingRequest.current) {
            const abort = pendingRequest.current.abort.bind(pendingRequest.current);
            // global state is cleared first due to onSearchComplete callback
            pendingRequest.current = null;
            abort();
        }

        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    const onSearchSuccess = response => {
        if (lastTermRef.current !== response.request.options.parameters.term) {
            return;
        }

        let data;
        try {
            data = JSON.parse(response.responseText);
        } catch (e) {
            fatalError(`Failed to parse JSON response: ${e.message}`);
            return;
        }

        if ('error' === data.status) {
            const result = data.statusMessages.reduce((res, message) => `${res}${message.title}: ${message.content}\n`, '');
            fatalError(result);
            return;
        }

        setResults(data);
        if (enterPressed.current) {
            goToItem();
        } else if (data.records.length > 0) {
            setSelectedItem(0);
        }
    };

    const onSearchFailure = response => {
        fatalError(`Search request failed due to following error: ${response.responseText}`);
    };

    const onSearchComplete = response => {
        if (!response || pendingRequest.current === response.request) {
            setSearchInProgress(false);
        }
    };

    const handleSaveRecentSearch = item => {
        const storeUrl = SEARCH_HISTORY_STORE_URL;
        const token = document.getElementById('forgery_protection_token').content;
        navigator.sendBeacon(storeUrl, toFormData({ ...item, forgery_protection_token: token }));
        flushRecentSearchCache();
    };

    const flushRecentSearchCache = () => {
        const { cache } = apolloClient;
        cache.evict({
            id: cache.identify({ __typename: 'Query' }),
            fieldName: 'recentSearch',
        });
        setHistory(null);
    };

    const renderSearchItems = (items, uatKey) => items.map(({ details, target, icon, link, title, label }, index) => {
        const iconUrl = (
            !icon ||
                icon.startsWith(Jsw.skinUrl) ||
                icon.startsWith('http://') ||
                icon.startsWith('https://') ||
                icon.startsWith('/modules/')
        ) ? icon : `${Jsw.skinUrl}${icon}`;

        return (
            <MenuItem
                data-action={uatKey}
                component={Link}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                to={link}
                state={{ reload: true }}
                title={details}
                target={target}
                label={label}
                active={selectedItem === index}
                icon={iconUrl ? <Icon className="main-header-search-result__icon" src={iconUrl} /> : null}
                className={MENU_ITEM_CLASSNAME}
                onClick={e => {
                    e.stopPropagation();
                    handleSaveRecentSearch({ details, target, icon, link, title });
                }}
            >
                {title}
            </MenuItem>

        );
    });

    const renderSearchResults = results => [
        results.records.length ? renderSearchItems(results.records, 'searchResultRegular') : (
            <div className="main-header-search-result__note main-header-search-result__note--empty">
                <Translate content="nothingFound" />
            </div>
        ),
        results.meta.moreResultsFound ? (
            <>
                <MenuDivider />
                <div className="main-header-search-result__note">
                    <Translate content="moreResultsFound" params={{ limit: config?.search?.limit }} />
                </div>
            </>
        ) : null,
    ];

    const renderHistory = history => history?.length ? [
        <Fragment key="history">
            <MenuHeader>
                <Translate content="recentSearch" />
            </MenuHeader>
        </Fragment>,
        renderSearchItems(history.map(item => ({
            ...item,
            label: (
                <Button
                    className="main-header-search-result__item-remove"
                    ghost
                    icon="cross-mark"
                    tooltip={<Translate content="removeFromRecent" />}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        searchTermRef.current.focus();
                        deleteRecentSearchResult({ variables: { input: { link: item.link } } });
                    }}
                />
            ),
        })), 'searchResultRecent'),
    ] : (
        <div className="main-header-search-result__note">
            <Status intent="info">
                <Hint><Translate content="recentSearchHint" /></Hint>
            </Status>
        </div>
    );

    const renderResults = () => {
        if (!isSearchFocused || (isSearchFocused && !((history) || results))) {
            return null;
        }

        return (
            <Menu
                id="searchResultsBlock"
                className="main-header-search-result"
                ref={menuRef}
            >
                {results ? renderSearchResults(results) : null}
                {results?.records.length === 0 ? (
                    <MenuDivider />
                ) : null}
                {(!results || results?.records.length === 0) ? renderHistory(history) : null}
            </Menu>
        );
    };

    const resetSearch = ({ resetValue = true } = {}) => {
        if (resetValue) {
            searchTermRef.current.value = '';
        }

        setSelectedItem(null);
        setResults(null);

        abortPreviousSearch();
        onSearchComplete();
    };

    const onArrowKeyPressed = key => {
        const records = results && results.records.length ? results.records : history;
        if (!records?.length) {
            return;
        }

        if (selectedItem === null) {
            setSelectedItem(0);
            return;
        }

        if (keyCode.DOWN_ARROW === key && selectedItem < records.length - 1) {
            setSelectedItem(selectedItem + 1);
        }

        if (keyCode.UP_ARROW === key && selectedItem > 0) {
            setSelectedItem(selectedItem - 1);
        }
    };

    const findTerm = () => {
        const term = searchTermRef.current.value.trim();

        if (lastTermRef.current === term || term.length < 3) {
            onSearchComplete();
            return;
        }
        lastTermRef.current = term;

        pendingRequest.current = new Ajax.Request(
            url,
            {
                method: 'get',
                parameters: { term: searchTermRef.current.value.trim() },
                onSuccess: onSearchSuccess,
                onFailure: onSearchFailure,
                onComplete: onSearchComplete,
            },
        );
    };

    const onDocumentTouchStart = useCallback(() => {
        touchMoved.current = false;
    }, [touchMoved]);
    const onDocumentTouchMove = useCallback(() => {
        touchMoved.current = true;
    }, [touchMoved]);
    const onDocumentTouchEnd = useCallback(() => {
        if (touchMoved.current) {
            return;
        }

        searchTermRef.current.blur();

        removeTouchEventListeners({ onDocumentTouchStart, onDocumentTouchMove, onDocumentTouchEnd });
    }, [touchMoved, searchTermRef, onDocumentTouchStart, onDocumentTouchMove]);

    const searchPlaceholder = useMemo(() => {
        if (isSearchFocused) {
            if (isAdmin) {
                return Locale.getSection('components.search-bar').lmsg('fieldStubHint');
            }
            return null;
        }
        return Locale.getSection('components.search-bar').lmsg('fieldStub');
    }, [isAdmin, isSearchFocused]);

    useEffect(() => () => {
        if (resetTimeout.current) {
            clearTimeout(resetTimeout.current);
        }

        removeTouchEventListeners({ onDocumentTouchStart, onDocumentTouchMove, onDocumentTouchEnd });
    }, [onDocumentTouchEnd, onDocumentTouchMove, onDocumentTouchStart, resetTimeout]);

    return (
        <div
            ref={containerRef}
            className="main-header-search"
            onMouseEnter={() => {
                headerRef.current.classList.add('page-header--search-hover');
            }}
            onMouseLeave={() => {
                headerRef.current.classList.remove('page-header--search-hover');
            }}
            onTouchEnd={e => {
                e.stopPropagation();
            }}
        >
            <div className={classNames('main-header-search__group', isSearchFocused && 'search-focused')}>
                <Input
                    innerRef={searchTermRef}
                    id="searchTerm"
                    type="text"
                    className="main-header-search__control"
                    autoComplete="off"
                    placeholder={searchPlaceholder}
                    onPaste={handlePaste}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    prefix={searchInProgress ? (
                        <span className="main-header-search__loader" />
                    ) : <Icon name="search" />}
                    suffix={(
                        <Icon
                            name="cross-mark"
                            onClick={e => e.stopPropagation()}
                        />
                    )}
                    size="fill"
                />
                {renderResults()}
            </div>
        </div>
    );
};


MainHeaderSearch.propTypes = {
    url: PropTypes.string.isRequired,
    headerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

export default MainHeaderSearch;
