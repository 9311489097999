// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import PageLayout from './PageLayout';

const withPageLayout = (Component, extraProps) => {
    const Wrapper = wrapperProps => {
        const {
            baseUrl,
            showFrames,
            pageHeader,
            pageSidebar,
            pageContentHeader,
            pageTitle,
            withSecondary,
            secondary,
            isSecondaryCollapsed,
            width,
            bodyClass,
            integrationClass,
            ...props
        } = {
            ...wrapperProps,
            ...(typeof extraProps === 'function' ? extraProps(wrapperProps) : extraProps),
        };

        return (
            <PageLayout
                baseUrl={baseUrl}
                showFrames={showFrames}
                pageHeader={pageHeader}
                pageSidebar={pageSidebar}
                pageContentHeader={pageContentHeader}
                pageTitle={pageTitle}
                withSecondary={withSecondary}
                isSecondaryCollapsed={isSecondaryCollapsed}
                secondary={secondary}
                width={width}
                bodyClass={bodyClass}
                integrationClass={integrationClass}
            >
                <Component {...props} />
            </PageLayout>
        );
    };
    Wrapper.displayName = `withPageLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default withPageLayout;
