// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormFieldPassword } from '@plesk/ui-library';
import { Locale, api } from 'jsw';
import estimatePassword from 'helpers/estimatePassword';
import { addToast } from 'helpers/toasts';
import Form from 'plesk/components/Form';
import Link from 'plesk/components/Link';
import { withLoginLayout } from '../layouts/LoginLayout';

const Translate = Locale.getTranslate('components.forms.restore-password');

const RestorePasswordPage = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const handleSubmit = values => {
        const errors = {};
        if (!values.password) {
            errors.password = {
                isEmpty: <Translate content="isEmpty" />,
            };
        }

        setErrors(errors);
        if (!Object.keys(errors).length) {
            return values;
        }
    };

    const handleSuccess = useCallback(() => {
        addToast({
            intent: 'success',
            message: <Translate content="restoreSuccess" />,
        });
        navigate('/login_up.php');
    }, [navigate]);

    const handleEstimatePassword = useCallback(password => estimatePassword(password, password => api.post('?estimate', { password })), []);

    return (
        <Form
            id="form-restore-password"
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            submitButton={{
                children: <Translate content="buttonRestore" />,
            }}
            applyButton={false}
            cancelButton={{
                children: <Translate content="buttonCancel" />,
                component: Link,
                to: '/login_up.php',
            }}
            errors={errors}
            vertical
        >
            <h3><Translate content="title" /></h3>
            <FormFieldPassword
                title={<Translate content="passwordLabel" />}
                name="password"
                size="fill"
                autoComplete="new-password"
                passwordMeterProps={{
                    onEstimate: handleEstimatePassword,
                    placement: 'top-right',
                }}
            />
        </Form>
    );
};

export default withLoginLayout(RestorePasswordPage);
